html,
body {
  min-height: 100vh;
}

body {
  font-family: Inter, sans-serif;
  font-size: 16px;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  @media screen and (width <= 600px) {
    font-size: 14px;
  }
}

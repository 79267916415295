.Footer {
  background: #25133F;
  padding: 3em;
  .top {
    text-align: center;
    .logo {
      height: 2em;
      aspect-ratio: 400 / 64;
    }
  }
  .contacts {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 1em;
    margin-top: 2em;
    .contact {
      width: 2em;
      height: 2em;
    }
  }
  .links {
    display: flex;
    flex-flow: row wrap;
    justify-content: center;
    align-items: center;
    gap: 1em;
    font-size: 0.8em;
    margin-top: 1em;
    .link {
      color: var(--palette-primary-main);
      text-decoration: none; // Only applies to <a>.
    }
  }
}

.ResponsiveNavBar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  gap: 1em;
  .logo {
    height: 2em;
    aspect-ratio: 400 / 64;
  }
  .link {
    font-weight: bold;
  }
  @media screen and (width <= 600px) {
    padding: 1em;
  }
}

.FormPane {
  padding: 2em;
  .top {
    display: flex;
    flex-flow: row wrap;
    justify-content: flex-start;
    align-items: center;
    gap: 2em;
    margin-bottom: 2em;
    .left {
      width: 1.5em;
      height: 1.5em;
      display: flex;
      justify-content: center;
      align-items: center;
      background: rgba(0 0 0 / 0.2);
      color: var(--palette-primary-main);
      font-size: 2em;
      font-weight: 500;
      border-radius: 50%;
    }
    .mid {
      flex: 1 1 0;
      .title {
        font-size: 1.5em;
        font-weight: bold;
      }
      .description {
        font-size: 0.8em;
        margin-top: 0.5em;
      }
    }
    .right {
      flex: 1 1 0;
    }
  }
  @media screen and (width <= 600px) {
    padding: 1em;
    .top {
      gap: 1em;
      .right {
        flex: 1 1 100%;
      }
    }
  }
}

.base {
  .headers {
    font-weight: bold;
    padding: 1em;
    .header {
      padding: 0 0.25em;
    }
  }
  .empty {
    background: rgba(255 255 255 / 0.05);
    text-align: center;
    border-radius: 0.75em;
    padding: 1em;
  }
  .rows {
    .row {
      border-radius: 0.75em;
      padding: 1em;
      &:nth-child(odd) {
        background: rgba(255 255 255 / 0.05);
      }
      .cells {
        align-items: center;
        .cell {
          padding: 0 0.25em;
        }
      }
    }
  }
  .pagination {
    display: flex;
    justify-content: flex-end;
    margin-top: 1em;
  }
}

.CompletePane {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  gap: 2em;
  padding: 2em;
  .left {
    width: 1.5em;
    height: 1.5em;
    display: flex;
    justify-content: center;
    align-items: center;
    background: rgba(0 0 0 / 0.2);
    color: var(--palette-primary-main);
    font-size: 2em;
    font-weight: 500;
    border-radius: 50%;
  }
  .mid {
    flex: 1 1 0;
    font-size: 1.5em;
    font-weight: bold;
  }
  .right {
    flex: 2 2 0;
    span {
      text-decoration: underline;
      text-underline-offset: 0.2em;
    }
    img {
      width: 2em;
      height: 2em;
      vertical-align: middle;
    }
  }
  @media screen and (width <= 600px) {
    gap: 1em;
    padding: 1em;
    .right {
      flex: 1 1 100%;
    }
  }
}

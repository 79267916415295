.CapsuleButton {
  width: 20em;
  background: linear-gradient(to right, #C24DFE, #6658DF);
  color: white;
  font-weight: bold;
  border-radius: 100em;
  padding: 0.5em;
  &:disabled {
    filter: opacity(20%) grayscale(100%);
    cursor: not-allowed;
  }
}

.App {
  min-height: 100vh;
  display: flex;
  flex-flow: column nowrap;
  background: black url("../../asset/Background.webp") center center / cover no-repeat;
  color: var(--palette-normal-text);
  .page {
    flex: 1 1 auto;
  }
}
